import React, { useState } from 'react'
import Detail from '../components/profile/Detail'
import { Image, Button, Upload, message } from 'antd';
import { FiEdit, FiSave } from 'react-icons/fi';
import { UploadOutlined } from '@ant-design/icons';
import "../styles/profile.scss";
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {getProfile, updateProfile} from "../apis/profile";
import { getError } from '../utility';
import OverlayLoader from '../components/loaders/OverlayLoader';

const Profile = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [initialState, setInitialState] = useState();
  const [currentState, setCurrentState] = useState();
  const queryClient = useQueryClient();
  const { isFetching } = useQuery(
    "get-profile",
    () => getProfile(),
    {
      onSuccess: (data) => {
        console.log(data.data.data);
        setInitialState(data.data.data);
        if (!isEditing) setCurrentState(data.data.data);
      },
      onError: (error) => message.error(getError(error)),
      refetchInterval: 5 * 1000 * 60,
    }
  )

  const { mutate: updateProfileMutate, isLoading: isUpdating } = useMutation((profile) => updateProfile({ profile }), {
    onSuccess: (data) => {
        message.success("Profile Updated successfully!");
        queryClient.invalidateQueries("get-profile");
        setIsEditing(false);
    },
    onError: (error) => message.error(getError(error))
})

  const checkAndUpdateDetails = (e) => {
    e.preventDefault();
    if (JSON.stringify(initialState) === JSON.stringify(currentState)) {
      return message.error("No Changes to save!");
    }
    if (currentState.api_key.length > 10) {
      return message.error("API Key can only be upto 10 letters long.")
    }
    updateProfileMutate({
      first_name: currentState.user.first_name,
      last_name: currentState.user.last_name,
      username: currentState.user.username,
      email: currentState.user.email,
      api_key: currentState.api_key,
      phone: currentState.phone,
    })
  }
  // const fileList = [
  //   {
  //     uid: '-1',
  //     name: 'yyy.png',
  //     status: 'done', // uploading, error
  //     // percent: 33,
  //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
  //     thumbUrl: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
  //   }
  // ];
  return (
    <main className='main-content'>
      <div className='content-header'>
        <h1 className='page-title'>Profile</h1>
        {initialState?.organization ? <h2 className='organization-title'>{initialState.organization}</h2> : null}
      </div>
      {isFetching && <OverlayLoader />}
      {isUpdating && <OverlayLoader title='Updating...' />}
        <div className='main-container profile-container'>
          <div className='profile-image-container'>
            <div className='profile-image'>
              <Image
                width={"100%"}
                src="/images/user-man.png"
              />
            </div>
            <div className='profile-image-uploader'>
              {/* <Upload
                action="https://api.recentair.com/api/clients/upload/profile/"
                listType="picture"
                defaultFileList={[...fileList]}
                maxCount="1"
              >
                <button className='button button-secondary'><UploadOutlined /> Upload Another</button>
              </Upload> */}
            </div>
            <div className='button-container'>
              {/* <button type='button' className='button button-regular'><FiSave /> Save Changes</button> */}
            </div>
          </div>
          <div className='profile-details-container'>
            <form onSubmit={checkAndUpdateDetails}>
              <div className='detail-group'>
                <Detail title={"First Name"} states={{initialState, currentState, setCurrentState, key: "first_name", isEditing: isEditing}} />
                <Detail title={"Last Name"} states={{initialState, currentState, setCurrentState, key: "last_name", isEditing: isEditing}} />
              </div>
              <Detail title={"Username"} states={{initialState, currentState, setCurrentState, key: "username", isEditing: isEditing}} />
              <div className='detail-group'>
                <Detail title={"Email"} states={{initialState, currentState, setCurrentState, key: "email", isEditing: isEditing}} />
                <Detail title={"Phone No.:"} states={{initialState, currentState, setCurrentState, key: "phone", isEditing: isEditing}} isClientProp={true}  />
              </div>
              <Detail title={"API Key"} states={{initialState, currentState, setCurrentState, key: "api_key", isEditing: isEditing}} isClientProp={true} />
              <div className='button-container'>
                {isEditing ? 
                <button type='submit' className='button button-regular' disabled={false}><FiSave /> Save</button>
                :
                <button type='button' className='button button-regular' onClick={(e) => {
                  e.preventDefault();
                  setIsEditing(true);
                }}><FiEdit /> Edit Details</button>
                }
              </div>
            </form>
          </div>
        </div>
    </main>
  )
}

export default Profile