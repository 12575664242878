export const getError = (error, defaultMessage = "Some unknown error occured") => {
    return (error.response?.data?.message || Object.values(error.response?.data)[0] || error.message || defaultMessage);
}

export const determineUser = () => {
    const accessToken = localStorage.getItem("c_token");
    if (accessToken) {
        return true;
    } else return false;
}
export const sluggify = (text) => {
    return text.toLowerCase().replaceAll(" ", "-").replaceAll(".", "");
}

export const getDateTime = (dateTime) => {
    if (!dateTime) {
        return;
    }
    dateTime += "+00:00";
    const date = new Date(dateTime);
    return date;
}

export const getShortDateTime = (dateTime) => {
    if (!dateTime) {
        return;
    }
    dateTime += "+00:00";
    const date = new Date(dateTime);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    if (year === new Date().getFullYear()) {
        if (month === new Date().getMonth()) {
            if (day === new Date().getDate()) {
                return date.toLocaleString('en-US', {timeZone: 'Asia/Katmandu', hour: 'numeric', minute: 'numeric', hour12: true})
            }
            return date.toLocaleString('en-US', {timeZone: 'Asia/Katmandu', day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric', hour12: true});
        }
        return date.toLocaleString('en-US', {timeZone: 'Asia/Katmandu', day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric', hour12: true});
    }
    return date.toLocaleString('en-US', {timeZone: 'Asia/Katmandu', year: 'numeric', day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric', hour12: true});
}
export const getShortDateTimeForMessage = (dateTime) => {
    if (!dateTime) {
        return;
    }
    const date = new Date(dateTime);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    if (year === new Date().getFullYear()) {
        if (month === new Date().getMonth()) {
            if (day === new Date().getDate()) {
                return date.toLocaleString('en-US', {timeZone: 'Asia/Katmandu', hour: 'numeric', minute: 'numeric', hour12: true})
            }
            return date.toLocaleString('en-US', {timeZone: 'Asia/Katmandu', day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric', hour12: true});
        }
        return date.toLocaleString('en-US', {timeZone: 'Asia/Katmandu', day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric', hour12: true});
    }
    return date.toLocaleString('en-US', {timeZone: 'Asia/Katmandu', year: 'numeric', day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric', hour12: true});
}


export const getStringFromSlug = (string) => {
    return string.replace('-', ' ');
}

export const getMessageStatus = (status) => {
    switch (status) {
        case '0':
            return "Sent";
        case '1':
            return "Heard";
        case '2':
            return "Accepted & Closed";
        case '3':
            return "Declined & Closed";
        default:
            return "Unknown";
    }
}