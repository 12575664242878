import React, { useEffect, useState } from 'react'
import { getShortDateTime } from '../../utility';

const WeatherSummary = ({weather, aqi}) => {
    const [weatherSorted, setWeatherSorted] = useState(weather);
    const getMeasureUnit = {
        'pm010': <span>μg/m<sup>3</sup></span>,
        'pm25': <span>μg/m<sup>3</sup></span>,
        'temperature': <span>&deg; C</span>,
        'pm10': <span>μg/m<sup>3</sup></span>,
        'voc': <span>VOC Index</span>,
        'rh': <span>%H</span>,
        'pm40': <span>μg/m<sup>3</sup></span>,
    };
    const getMeasureName = {
        'pm010': 'PM 1.0',
        'pm25': 'PM 2.5',
        'temperature': 'Temperature',
        'pm10': 'PM 10',
        'voc': 'VOC',
        'rh': 'RH',
        'pm40': 'PM 4',
    };
    const getThresholdColor = (elem) => {
        // if (elem.measure_name === 'rh') {
            if (elem.measure_value < 28)
                return 'blue'
            else if (elem.measure_value < 38)
                return 'green'
            else if (elem.measure_value < 48)
                return 'yellow'
            else if (elem.measure_value < 58)
                return 'red'
            else
                return 'red'
        // }
        // return 'blue';
    }
    useEffect(() => {
        const order = ['temperature', 'rh', 'pm010', 'pm25', 'pm40', 'pm10', 'voc'];
        let data = [];
        let i, j;
        for (i = 0; i < weather.length; i++) {
            for (j = 0; j < weather.length; j++) {
                if (weather[j]['measure_name'] === order[i]) {
                    data.push(weather[j]);
                }
            }
        }
        setWeatherSorted(data);
    }, [weather])
  return (
    <div className="summary">
        <div className='aqi-box'>
            <p className='value'>{aqi?.measure_value || "--"}</p>
            <p className='lable'>AQI</p>
            <p className='time'>{getShortDateTime(aqi?.time) || "--"}</p>
        </div>
        <p className='temperature'>{weatherSorted?.find(elem => elem.measure_name === 'temperature')?.measure_value || "-"} {getMeasureUnit[weatherSorted?.find(elem => elem.measure_name === 'temperature')?.measure_name]}</p>
        {weatherSorted?.map((elem, i) =>
        elem.measure_name !== 'temperature' &&
        <div className='weather-element' key={i}>
            <p className={'lable'}>{getMeasureName[elem.measure_name]}</p>
            <span className={`circle circle-${getThresholdColor(elem)}`}></span>
            <p className='value'>{elem.measure_value} {getMeasureUnit[elem.measure_name]}</p>
        </div>
        )}
        <p className='time'>{getShortDateTime(weatherSorted[0]?.time)}</p>
    </div>
  )
}

export default WeatherSummary