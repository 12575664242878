import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import MainHeader from "./MainHeader";
import Footer from "./Footer";
import SideNav from './SideNav';

const AppLayout = () => {
    const [navOpen, setNavOpen] = useState(false);
    const setNavState = (e) => {
        setNavOpen(!navOpen);
    }
    const navUtils = {
        navOpen, setNavOpen, setNavState
    };
  return (
    <div className='app'>
      <MainHeader navUtils={navUtils} />
      <SideNav navUtils={navUtils} />
      <Outlet />
      <Footer />
    </div>
  )
}

export default AppLayout