import { Spin } from 'antd'
import React from 'react'
import { useEffect } from 'react'

const OverlayLoader = ({title = "Loading...", key}) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    }
  }, [key])
  return (
    <div className='overlay-loader-container'>
        <div className='overlay-loader'>
            <div className='spin'><Spin size="large" /></div>
            <p className='spin-title'>{title}</p>
        </div>
    </div>
  )
}

export default OverlayLoader