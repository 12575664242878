import { message } from 'antd';
import React from 'react'
import { FiCopy, FiLock } from 'react-icons/fi';
import { apiDomainOnly } from '../../constants';

const ApiHeader = ({api}) => {
    const {method, title, url, extras} = api;
    const {authentication_required} = extras;
    const setClipboard = () => {
        navigator.clipboard.writeText(`${apiDomainOnly}${url}`).then(
        () => {
            message.success("URL Copied to your clipboard.")
        },
        (error) => {
            message.error("Couldn't copy to the clipboard.")
        }
        );
    }
  return (
    <div className='api-header'>
        <div className='header-left'>
            <span className='api-method'>{method}</span>
            <span className='api-title'>{title}</span>
        </div>
        <div className='header-right'>
            <span className='api-url' title="Click to Copy" onClick={setClipboard}>
                <input type="hidden" defaultValue={url} readOnly />
                <span className='url'>{url}</span>
                <FiCopy style={{verticalAlign: "middle"}} />
            </span>
            <div className='extras'>
                {authentication_required &&
                <span className='icon' title="Authentication required"><FiLock /></span>
                }
            </div>
        </div>
    </div>
  )
}

export default ApiHeader