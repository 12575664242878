import { Avatar, message } from 'antd'
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Dropdown, Space } from 'antd';
import React from 'react'
import { FiBell, FiMenu, FiLogOut } from "react-icons/fi";
import { MdClose } from 'react-icons/md';
import "../styles/main-header.scss";
import Notification from './Notification';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthProvider';

const MainHeader = ({navUtils}) => {
    const {navOpen, setNavOpen, setNavState} = navUtils;
    const navigate = useNavigate();
      const handleMenuClick = (e) => {
        console.log(e.key);
        switch (parseInt(e.key)) {
            case 1:
                navigate("/profile");
                break;
            case 2:
                logOutHandler();
                break;
            case 3:
                logOutHandler();
                break;
            default:
                // do nothing
        }
      };
      const {currentUser} = useAuth();
    const items = [
        {
          label: currentUser?.user.first_name ? `${currentUser?.user.first_name} ${currentUser?.user.last_name}` : currentUser?.user.username,
          key: '1',
          icon: <UserOutlined />,
        },
        {
          label: 'Log Out',
          key: '2',
          icon: <FiLogOut />,
          danger: true,
        },
        {
          label: 'Log Out All',
          key: '3',
          icon: <FiLogOut />,
          danger: true,
        },
      ];
      const menuProps = {
        items,
        onClick: handleMenuClick,
      };
      const { logoutClientFromApi } = useAuth();
      const logOutHandler = () => {
        logoutClientFromApi();
      }

      const renderNotifications = () => {
        return (
            <div className='notification-container'>
                <Notification />
            </div>
        )
      }
  return (
    <header className='main-header'>
        <div className='hamburger-initiater only-mobile'>
            <button type='button' onClick={setNavState} className=''>
                {navOpen ? <MdClose /> : <FiMenu />}
            </button>
        </div>
        <div className='logo-container'>
            <img src='/images/only-logo.png' alt='' />
            <span className='name-container'>
                <h2 className='heading'>Recent Air</h2>
                <h3 className='sub-heading'>Client Dashboard</h3>
            </span>
        </div>
        <div className='main-options'>
            <div role='button' className='option bell'>
                <Dropdown trigger={["click", "contextMenu"]} dropdownRender={renderNotifications}>
                    <Button>
                        <FiBell />
                    </Button>
                </Dropdown>
            </div>
            <div role='button' className='option'>
                <Dropdown menu={menuProps} trigger={["click", "contextMenu"]}>
                    <Button>
                        <Space>
                            <span className='username only-tablet'>{currentUser?.user.username} </span> <Avatar icon={<UserOutlined />} size="small" />
                            <DownOutlined />
                        </Space>
                    </Button>
                </Dropdown>
            </div>
        </div>
    </header>
  )
}

export default MainHeader