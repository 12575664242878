import React from 'react'
import JsonFormatter from 'react-json-formatter'

const ApiBody = ({body, response, urlexample, extras}) => {
  const jsonStyle = {
    propertyStyle: { color: 'red' },
    stringStyle: { color: 'green' },
    numberStyle: { color: 'darkorange' }
  }
  return (
    <>
      <div className='main-api-content' dangerouslySetInnerHTML={{__html: body}}></div>
      <p className='title'>URL Example:</p>
      <a href={urlexample} className='url-example' target='_blank'>{urlexample}</a>
      {extras.payload && <div><p className='title'>Payload:</p><JsonFormatter json={extras.payload} tabWith={4} jsonStyle={jsonStyle} /></div>}
      {response && <><p className='title'>Response</p>
      <JsonFormatter json={response} tabWith={4} jsonStyle={jsonStyle} /></>}
    </>
  )
}

export default ApiBody