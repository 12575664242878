import { apiDomain } from "../constants";
import axios from "../myaxios";
import normalaxios from "axios"

export const login = async ({user}) => {
    return await normalaxios.post(`${apiDomain}clients/login/`, user);
}

export const logout = async () => {
    return await axios.post(`${apiDomain}clients/logout/`);
}

export const logoutall = async () => {
    return await axios.post(`${apiDomain}clients/logoutall/`);
}