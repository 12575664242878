import "../../styles/download-prompt.scss";
import { Checkbox, Divider, message } from 'antd';
import { useEffect, useState } from 'react';
import { useMutation } from "react-query";
import { downloadData } from "../../apis/places";
import { getError } from "../../utility";


const CheckboxGroup = Checkbox.Group;
const plainOptions = ['AQI', 'Temperature', 'RH', 'PM 1.0', 'PM 2.5', 'PM 4.0', 'PM 10', 'VOC'];
const defaultCheckedList = plainOptions;


const DownloadPropmt = ({ setShowDownloadPrompt, showDownloadPrompt, place, from_str, to_str }) => {
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(true);
  const [downloading, setDownloading] = useState(false);

  const { mutate: downloadMutate, isLoading: isUpdating } = useMutation(() => downloadData({place, from_str, to_str, as: showDownloadPrompt, datafor: checkedList}), {
    onSuccess: (data) => {
      const url = window.URL.createObjectURL(new Blob([data.data]));
      console.log(data);
      const link = document.createElement('a');
      link.href = url;
      if (data.headers["content-type"] == "text/csv") {
        link.setAttribute('download', 'data.csv');
      } else {
        link.setAttribute('download', 'data.json');
      }
      document.body.appendChild(link);
      link.click();
      setDownloading(false);
      setShowDownloadPrompt(false);
    },
    onError: (error) => {
      setShowDownloadPrompt(false);
      message.error(getError(error));
    }
})

  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };
  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const handleDownload = () => {
    setDownloading(true);
    console.log(checkedList, showDownloadPrompt);
    downloadMutate();
  }

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => document.body.style.overflow = "auto";
  }, []);

  return (
    <>
      <div className='overlay-prompt' onClick={() => setShowDownloadPrompt("")}>
      </div>
      <div className='download-prompt'>
        <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
            All
        </Checkbox>
        <Divider />
        <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} className="checkbox-group" />
        <Divider />
        <button className="button button-primary" disabled={downloading} onClick={() => handleDownload()}>{downloading ? "Downloading..." : "Download"}</button>
      </div>
    </>
  );
};
export default DownloadPropmt;