import React, { useState } from 'react'
import Sensor from '../components/sensors/Sensor';
import "../styles/all-sensors.scss";
import { getInitialPlaces, getInitialPlacesDetail } from '../apis/places';
import { useQuery } from 'react-query';
import { message } from 'antd';
import { getError } from "../utility";
import OverlayLoader from "../components/loaders/OverlayLoader";

const AllSensors = () => {
    const [places, setPlaces] = useState([]);
    const [placesData, setPlacesData] = useState([]);

    const { isLoading: isLoadingData } = useQuery(
      "get-places-data",
      () => getInitialPlaces(),
      {
        onSuccess: (data) => {
          setPlacesData(data.data.data.rows);
        },
        onError: (error) => message.error(getError(error)),
        refetchInterval: 5 * 1000 * 60,
      }
    )
    
    const { isLoading } = useQuery(
      "get-places",
      () => getInitialPlacesDetail(),
      {
        onSuccess: (data) => {
          setPlaces(data.data.data);
        },
        onError: (error) => message.error(getError(error)),
        refetchInterval: 5 * 1000 * 60,
      }
    )

  return (
    <main className='main-content'>
      <div className='content-header'>
        <h1 className='page-title'>All Places <sub>Sensors</sub></h1>
      </div>
        <div className='main-container sensor-container'>
          {(places.length > 0 && placesData.length > 0) ? places.map((place, i) => 
            <Sensor place={place} data={placesData[i] || null} key={place.slug} />
          ) : <OverlayLoader />}
        </div>
    </main>
  )
}

export default AllSensors