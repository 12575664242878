import React from 'react';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { getShortDateTime } from '../../utility';

const AQIBarChart = ({ measure_data, title, unit }) => {
  const thresholds = [
    { name: 'Good', value: 50, color: '#00E400' },
    { name: 'Moderate', value: 100, color: '#F4D03F' },
    { name: 'Unhealthy for Sensitive Groups', value: 150, color: '#FF7E00' },
    { name: 'Unhealthy', value: 200, color: '#FF0000' },
    { name: 'Very Unhealthy', value: 300, color: '#8F3F97' },
    { name: 'Hazardous', value: 500, color: '#7E0023' },
  ];

  const data = measure_data?.map(measure => {
    const value = parseFloat(measure.measure_value);
    const threshold = thresholds.find(threshold => value <= threshold.value);
    const color = threshold ? threshold.color : '#8884d8';
    return {
      time: getShortDateTime(measure.time),
      value,
      fill: color,
    };
  });

  return (
    <div className='graph child'>
      <h2 style={{textAlign: "center", marginBottom: "0.5rem"}}>{title}</h2>
      {measure_data ? <ResponsiveContainer width="100%" height={"100%"}>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="time" tick={{ fontSize: 12 }} />
          <YAxis
            label={{
              value: unit,
              angle: -90,
              position: 'insideLeft',
              offset: 10,
              style: { fontSize: 12 },
            }}
          />
          <Tooltip />
          <Legend />
          <Bar dataKey="value" name={title} />
        </BarChart>
      </ResponsiveContainer>
      : <div className='loading-graph-container'>
      <div className='image-container'>
        <img src="/images/only-logo.png" alt="" />
      </div>
      <p className='text'>Loading...</p>
    </div>}
    </div>
  );
};

export default AQIBarChart;
