import { message } from "antd";
import axios from "./myaxios";
import { createContext, useContext, useState } from "react";
import { apiDomain } from "./constants";
import { determineUser } from "./utility";
import { getProfile } from "./apis/profile";
import { useQuery } from "react-query";

let AuthContext = createContext(null);

export default function AuthProvider({ children }) {
    const [loggedUser, setLoggedUser] = useState(determineUser());
    const [currentUser, setCurrentUser] = useState(null);
    useQuery(
        "get-profile",
        () => getProfile(),
        {
          onSuccess: (data) => {
            setCurrentUser(data.data.data);
          },
          onError: (error) => {
            message.error("Log in first!")
            return logoutClient();
            },
          refetchInterval: 5 * 1000 * 60,
          enabled: loggedUser,
        }
      )
    const loginFinalise = (token, callback) => {
        localStorage.setItem("c_token", token);
        setLoggedUser(determineUser());
        callback();
      };
    const logoutClient = () => {
        localStorage.removeItem("c_token");
        setLoggedUser(determineUser());
    };
    const logoutClientFromApi = async () => {
        await axios.post(`${apiDomain}clients/logout/`)
        .then((data) => {
            message.success('Logged Out!')
        })
        .catch((error) => {
            console.log(error)
        })
        .then(()=> logoutClient())
    }
    const logoutAllFromApi = async () => {
        await axios.post(`${apiDomain}clients/logoutall/`)
        .then((data) => {
            message.success('Every of your session ended!')
            console.log(data);
        })
        .catch((error) => {
            console.log(error)
        })
        .then(()=> logoutClient())
    }
    let value = {
        currentUser,
        loginFinalise,
        logoutClient,
        logoutClientFromApi,
        logoutAllFromApi,
        loggedUser,
    }
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
function useAuth() {
    return useContext(AuthContext);
}

export { useAuth };