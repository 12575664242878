import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Brush } from 'recharts';
import { getDateTime, getShortDateTime } from '../../utility';
import { useEffect, useState } from 'react';
import "../../styles/graph.scss";

const CustomTooltip = ({ active, payload, label, unit, title }) => {
  if (active && payload && payload.length) {
    return (
      <div className='recharts-tooltip'>
        <p className='title'>{`${title}`}</p>
        <p className='value'>{`${payload[0].value} ${unit}`}</p>
        <p className='date'>{`${getShortDateTime(label)}`}</p>
      </div>
    )
  }
}

const TimeSeriesChart = ({ measure_data, title, unit }) => {
  const [data, setData] = useState(null);
  const [currentData, setCurrentData] = useState(null);

  useEffect(() => {
    setData(
      measure_data?.map(measure => ({
        date: getDateTime(measure.time),
        [title]: parseFloat(measure.measure_value),
      }))
    );
  }, [measure_data]);

  const handleBrushChange = (domain) => {
    if (domain && domain.length === 2) {
      const [start, end] = domain;
      setCurrentData(data.filter(entry => entry.date >= start && entry.date <= end));
    } else {
      setCurrentData(null);
    }
  };

  return (
    <div className='graph child'>
      <h2 style={{ textAlign: 'center', marginBottom: '0.5rem' }}>{title}</h2>
      {data ? (
        <ResponsiveContainer width="100%" height={"100%"}>
          <LineChart data={currentData ? currentData : data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" tick={{ fontSize: 12, style: { marginTop: 10 } }} tickFormatter={(value) => `${getShortDateTime(value)}`} />
            <YAxis
              // label={{ value: title, angle: -90, position: 'insideLeft' }}
              tick={{ fontSize: 12, style: { whiteSpace: 'nowrap' } }}
              tickFormatter={(value) => `${value} ${unit}`}
            />
            <Tooltip content={<CustomTooltip unit={unit} title={title} />} />
            <Legend />
            <Line type="monotone" dataKey={title} stroke="#8884d8" activeDot={{ r: 8 }} />
            <Brush dataKey="date" height={30} stroke="#8884d8" onChange={handleBrushChange} tickFormatter={(value) => `${getShortDateTime(value)}`} />
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <div className='loading-graph-container'>
          <div className='image-container'>
            <img src="/images/only-logo.png" alt="" />
          </div>
          <p className='text'>Loading...</p>
        </div>
      )}
    </div>
  );
};

export default TimeSeriesChart;
