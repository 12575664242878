import { Card } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { apiDomainOnly } from '../../constants'

const Sensor = ({place, data}) => {
  return (
    <Link to={`/places/${place.slug}`} className='sensor'>
        <Card loading={false}>
            <div className='image-container'>
                <img src={place.image ? `${apiDomainOnly}${place.image}` : "/images/sample-city.jpg"} alt="" />
            </div>
            <div className='data-container'>
                <div className='data-left-container'>
                    <p className='heading'>{place.name}</p>
                    <p className='sub-heading'>{place.city}</p>
                </div>
                <div className='data-right-container'>
                    <p className='temperature' dangerouslySetInnerHTML={{__html: data?.measure_value ? data.measure_value + "&deg;C" : "--"}} />
                </div>
            </div>
        </Card>
    </Link>
  )
}

export default Sensor