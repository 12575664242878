import React from 'react'
import { Button, Collapse, Dropdown } from 'antd';
import ApiHeader from '../components/apis/ApiHeader';
import ApiBody from "../components/apis/ApiBody";
import "../styles/apis.scss";
import { BiExport } from 'react-icons/bi';
const { Panel } = Collapse;

const Apis = () => {
  const apis = [
    {
      method: "get",
      slug: "all-sensors",
      title: "Get All of Your Sensors",
      url: "/api/v1/sensors/",
      urlexample: "https://api.recentair.com/api/v1/sensors/?api_key=abcdefghij",
      extras: {
        authentication_required: true,
      },
      body: "Get the information of all assigned sensors to you.",
      response: `
      {
  "success": true,
  "data": [
    {
      "id": 1,
      "name": "Koteshwor",
      "slug": "koteshwor",
      "lat": "27.6756",
      "lon": "85.3459",
      "off": false,
      "city": "kathmandu",
      "image": "/media/images/things/koteshwor_Grz5Bvz.jpg"
    },
    {
      "id": 2,
      "name": "Baneshwor",
      "slug": "baneshwor",
      "lat": "27.6915",
      "lon": "85.3420",
      "off": false,
      "city": "kathmandu",
      "image": "/media/images/things/koteshwor_zoETMp7.jpg"
    },
    {
      "id": 4,
      "name": "Dharan",
      "slug": "dharan",
      "lat": "87.126",
      "lon": "87.126",
      "off": false,
      "city": "dharan",
      "image": null
    },
    {
      "id": 5,
      "name": "Bijuli Bazar",
      "slug": "bijuli-bazar",
      "lat": "27.6922",
      "lon": "85.3302",
      "off": false,
      "city": "kathmandu",
      "image": null
    }
  ],
  "message": ""
}
      `,
    },
    {
      method: "get",
      slug: "latest-data-view",
      title: "Get Latest data of a Place",
      url: "/api/v1/latest/<place slug>/",
      urlexample: "https://api.recentair.com/api/v1/latest/koteshwor/",
      extras: {
        authentication_required: true,
      },
      body: "Get the latest data point of the particular place.",
      response: `
      {
        "success": true,
        "data": {
          "aqi": {
            "column_info": [
              {
                "name": "place",
                "type": {
                  "ScalarType": "VARCHAR"
                }
              },
              {
                "name": "measure_name",
                "type": {
                  "ScalarType": "VARCHAR"
                }
              },
              {
                "name": "time",
                "type": {
                  "ScalarType": "TIMESTAMP"
                }
              },
              {
                "name": "measure_value",
                "type": {
                  "ScalarType": "DOUBLE"
                }
              }
            ],
            "rows": [
              {
                "place": "koteshwor",
                "measure_name": "aqi",
                "time": "2022-12-21 06:25:07.360000000",
                "measure_value": "55.0"
              }
            ]
          },
          "other": {
            "column_info": [
              {
                "name": "city",
                "type": {
                  "ScalarType": "VARCHAR"
                }
              },
              {
                "name": "sensor_no",
                "type": {
                  "ScalarType": "VARCHAR"
                }
              },
              {
                "name": "measure_name",
                "type": {
                  "ScalarType": "VARCHAR"
                }
              },
              {
                "name": "time",
                "type": {
                  "ScalarType": "TIMESTAMP"
                }
              },
              {
                "name": "measure_value",
                "type": {
                  "ScalarType": "BIGINT"
                }
              }
            ],
            "rows": [
              {
                "city": "koteshwor",
                "sensor_no": "10",
                "measure_name": "pm25",
                "time": "2022-12-22 19:09:42.661000000",
                "measure_value": "42"
              },
              {
                "city": "koteshwor",
                "sensor_no": "10",
                "measure_name": "voc",
                "time": "2022-12-22 19:09:42.661000000",
                "measure_value": "68"
              },
              {
                "city": "koteshwor",
                "sensor_no": "10",
                "measure_name": "temperature",
                "time": "2022-12-22 19:09:42.661000000",
                "measure_value": "93"
              },
              {
                "city": "koteshwor",
                "sensor_no": "10",
                "measure_name": "pm40",
                "time": "2022-12-22 19:09:42.661000000",
                "measure_value": "42"
              },
              {
                "city": "koteshwor",
                "sensor_no": "10",
                "measure_name": "pm010",
                "time": "2022-12-22 19:09:42.661000000",
                "measure_value": "31"
              },
              {
                "city": "koteshwor",
                "sensor_no": "10",
                "measure_name": "pm10",
                "time": "2022-12-22 19:09:42.661000000",
                "measure_value": "53"
              },
              {
                "city": "koteshwor",
                "sensor_no": "10",
                "measure_name": "rh",
                "time": "2022-12-22 19:09:42.661000000",
                "measure_value": "33"
              }
            ]
          },
          "thing": {
            "id": 1,
            "city": {
              "id": 1,
              "district": {
                "id": 1,
                "province": {
                  "id": 1,
                  "name": "Bagmati",
                  "slug": "bagmati"
                },
                "name": "Kathmandu",
                "slug": "kathmandu"
              },
              "name": "Kathmandu",
              "slug": "kathmandu"
            },
            "name": "Koteshwor",
            "slug": "koteshwor",
            "image": "/media/images/things/koteshwor_Grz5Bvz.jpg",
            "lat": "27.6756",
            "lon": "85.3459",
            "off": false,
            "downloaded": false,
            "certificate_id": "no"
          },
          "thing_status": "not-running"
        },
        "message": ""
      }
      `,
    },
    {
      method: "get",
      slug: "main-data-view",
      title: "Get data of a Place in a time range",
      url: "/api/v1/<place slug>/",
      urlexample: "https://api.recentair.com/api/v1/koteshwor/",
      extras: {
        authentication_required: true,
        payload: `{
          "from_str": "2023-06-27T01:01:09.695Z",
          "place": "baneshwor",
          "to_str": "2023-06-26T01:01:09.695Z"
        }`,
      },
      body: "Get the data point of the particular place between a time.",
      response: `
      {
        "success": true,
        "data": {
          "aqi": {
            "column_info": [
              {
                "name": "place",
                "type": {
                  "ScalarType": "VARCHAR"
                }
              },
              {
                "name": "measure_name",
                "type": {
                  "ScalarType": "VARCHAR"
                }
              },
              {
                "name": "time",
                "type": {
                  "ScalarType": "TIMESTAMP"
                }
              },
              {
                "name": "measure_value",
                "type": {
                  "ScalarType": "DOUBLE"
                }
              }
            ],
            "rows": [
              {
                "place": "koteshwor",
                "measure_name": "aqi",
                "time": "2022-12-21 06:25:07.360000000",
                "measure_value": "55.0"
              }
            ]
          },
          "other": {
            "column_info": [
              {
                "name": "city",
                "type": {
                  "ScalarType": "VARCHAR"
                }
              },
              {
                "name": "sensor_no",
                "type": {
                  "ScalarType": "VARCHAR"
                }
              },
              {
                "name": "measure_name",
                "type": {
                  "ScalarType": "VARCHAR"
                }
              },
              {
                "name": "time",
                "type": {
                  "ScalarType": "TIMESTAMP"
                }
              },
              {
                "name": "measure_value",
                "type": {
                  "ScalarType": "BIGINT"
                }
              }
            ],
            "rows": [
              {
                "city": "koteshwor",
                "sensor_no": "10",
                "measure_name": "pm25",
                "time": "2022-12-22 19:09:42.661000000",
                "measure_value": "42"
              },
              {
                "city": "koteshwor",
                "sensor_no": "10",
                "measure_name": "voc",
                "time": "2022-12-22 19:09:42.661000000",
                "measure_value": "68"
              },
              {
                "city": "koteshwor",
                "sensor_no": "10",
                "measure_name": "temperature",
                "time": "2022-12-22 19:09:42.661000000",
                "measure_value": "93"
              },
              {
                "city": "koteshwor",
                "sensor_no": "10",
                "measure_name": "pm40",
                "time": "2022-12-22 19:09:42.661000000",
                "measure_value": "42"
              },
              {
                "city": "koteshwor",
                "sensor_no": "10",
                "measure_name": "pm010",
                "time": "2022-12-22 19:09:42.661000000",
                "measure_value": "31"
              },
              {
                "city": "koteshwor",
                "sensor_no": "10",
                "measure_name": "pm10",
                "time": "2022-12-22 19:09:42.661000000",
                "measure_value": "53"
              },
              {
                "city": "koteshwor",
                "sensor_no": "10",
                "measure_name": "rh",
                "time": "2022-12-22 19:09:42.661000000",
                "measure_value": "33"
              }
            ]
          },
          "thing": {
            "id": 1,
            "city": {
              "id": 1,
              "district": {
                "id": 1,
                "province": {
                  "id": 1,
                  "name": "Bagmati",
                  "slug": "bagmati"
                },
                "name": "Kathmandu",
                "slug": "kathmandu"
              },
              "name": "Kathmandu",
              "slug": "kathmandu"
            },
            "name": "Koteshwor",
            "slug": "koteshwor",
            "image": "/media/images/things/koteshwor_Grz5Bvz.jpg",
            "lat": "27.6756",
            "lon": "85.3459",
            "off": false,
            "downloaded": false,
            "certificate_id": "no"
          },
          "thing_status": "not-running"
        },
        "message": ""
      }
      `,
    },
  ]
  const onChange = (key) => {
    console.log(key);
  };
  const items = [
    {
      label: 'Postman Export',
      key: '1',
    },
  ];
  const renderExportOptions = () => {
    return (
    <div className='export-options-container'>
      <a href='/to-download/postman-export.json' target="_blank" download className='export-option'>Postman Export</a>
      <a href='/to-download/api-data.json' target='_blank' download className='export-option'>JSON Export</a>
    </div>)
  }
  return (
    <main className='main-content'>
      <div className='content-header'>
        <h1 className='page-title'>APIs <sub>doc</sub></h1>
        <Dropdown trigger={["click", "contextMenu"]} dropdownRender={renderExportOptions}>
            <button className='button button-primary'>
              <BiExport style={{verticalAlign: "middle"}} />&nbsp;&nbsp;Export APIs
            </button>
        </Dropdown>
      </div>
      <div className='main-container api-container'>
        <Collapse defaultActiveKey={['0']} onChange={onChange}>
          {apis.map((api, index) =>
            <Panel header={<ApiHeader api={api} key={index} />} key={index}>
              <ApiBody body={api.body} response={api.response} urlexample={api.urlexample} extras={api.extras}/>
            </Panel>
            )}
        </Collapse>
      </div>
    </main>
  )
}

export default Apis