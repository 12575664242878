import { Card, Dropdown, Select, message } from 'antd'
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import { BiExport } from 'react-icons/bi'
import MeasuresSummary from '../components/sensors/MeasuresSummary';
import { getError, getStringFromSlug } from "../utility";
import { TbWorldLatitude, TbWorldLongitude} from "react-icons/tb";
import "../styles/sensor.scss";
import TimeSeriesChart from '../components/graph/TimeSeriesChart';
import AQIBarChart from '../components/graph/AQIBarChart';
import { useAuth } from '../AuthProvider';
import { useQuery } from 'react-query';
import { getParticularPlace, getParticularPlaceData } from "../apis/places";
import OverlayLoader from '../components/loaders/OverlayLoader';
import DownloadPrompt from "../components/download/DownloadPrompt";

const getCurrentTimeString = () => {
  const currentTime = new Date();
  const from_time = currentTime.toISOString().slice(0, -1) + 'Z';
  return from_time;
}
const timeRanges = {
  'now-1h': 60 * 60 * 1000,
  'now-12h': 12 * 60 * 60 * 1000,
  'now-24h': 24 * 60 * 60 * 1000,
  'now-2d': 2 * 24 * 60 * 60 * 1000,
  'now-7d': 7 * 24 * 60 * 60 * 1000,
  'now-1m': 30 * 24 * 60 * 60 * 1000,
  'now-6m': 6 * 30 * 24 * 60 * 60 * 1000,
  'now-1y': 12 * 30 * 24 * 60 * 60 * 1000,
  'now-2y': 2 * 12 * 30 * 24 * 60 * 60 * 1000,
};
const Sensor = () => {
    const {currentUser} = useAuth();
    const [defaultTimeRange, setDefaultTimeRange] = useState("now-24h");
    const [thingStatus, setThingStatus] = useState('Loading...');
    const {slug} = useParams();
    const [weather, setWeather] = useState([]);
    const [thing, setThing] = useState();
    const [aqiData, setAqiData] = useState();
    const [fromTime] = useState(getCurrentTimeString())
    const [graphData, setGraphData] = useState();
    const [showDownloadPrompt, setShowDownloadPrompt] = useState("");

    function subtractTimeRange() {
    
      if (defaultTimeRange in timeRanges) {
        const timeDiff = timeRanges[defaultTimeRange];
        const fromTimeDate = new Date(fromTime);
        const toTimeDate = new Date(fromTimeDate - timeDiff);
        return toTimeDate.toISOString();
      } else {
        return '-1';
      }
    }
    

    const { isFetching, isLoading } = useQuery(
      ["get-particular-place-currents", slug],
      () => getParticularPlace({place: slug, api_key: currentUser?.api_key}),
      {
        onSuccess: (data) => {
          setAqiData(data.data.data.aqi.rows)
          setThing(data.data.data.thing)
          setWeather(data.data.data.other.rows)
          setThingStatus(data.data.data.thing_status)
        },
        onError: (error) => message.error(getError(error)),
        refetchInterval: 5 * 1000 * 60,
        enabled: currentUser ? true : false,
      }
    )
    const { isFetching: isGetting, refetch } = useQuery(
      ["get-particular-place-data", slug],
      () => {
        if (currentUser) {
          return getParticularPlaceData({
            place: slug,
            api_key: currentUser.api_key,
            from_str: fromTime,
            to_str: subtractTimeRange(),
          });
        } else {
          return Promise.resolve(null);
        }
      },
      {
        onSuccess: (data) => {
          if (data) setGraphData(data.data.data);
        },
        onError: (error) => message.error(getError(error)),
        refetchInterval: 5 * 1000 * 60,
        enabled: currentUser ? true : false,
      }
    )
    useEffect(() => {
      refetch();
    }, [defaultTimeRange])
    
    const dateOptions = [
      {
          value: 'now-1h',
          label: 'Last 1h',
      },
      {
          value: 'now-12h',
          label: 'Last 12h',
      },
      {
          value: 'now-24h',
          label: 'Last 24h',
      },
      {
          value: 'now-2d',
          label: 'Last 2d'
      },
      {
          value: 'now-7d',
          label: 'Last 7d'
      },
      {
          value: 'now-1m',
          label: 'Last 1m'
      },
      {
          value: 'now-6m',
          label: 'Last 6m'
      },
      {
          value: 'now-1y',
          label: 'Last 1y'
      },
      {
          value: 'now-2y',
          label: 'Last 2y'
      },
      {
          value: 'all',
          label: 'All'
      },
    ]
    const renderExportOptions = () => {
        return (
          <div className='export-options-container'>
            <button onClick={() => setShowDownloadPrompt("csv")} className='export-option'>As .csv</button>
            <button onClick={() => setShowDownloadPrompt("json")} className='export-option'>As .json</button>
          </div>
          )
    }
      const measure_data_aqi = () => {
        const measureData = [];
      
        for (let i = 0; i < 180; i++) {
          const measureValue = Math.floor(Math.random() * 500);      
          measureData.push({
            city: "baneshwor",
            measure_name: "aqi",
            measure_value: measureValue,
            aqi: measureValue,
            time: new Date(),
          });
        }
      
        return measureData;
      };
  return (
    <>
    {showDownloadPrompt &&
    <DownloadPrompt setShowDownloadPrompt={setShowDownloadPrompt} showDownloadPrompt={showDownloadPrompt} place={slug} from_str={fromTime} to_str={subtractTimeRange()} />
    }
    <main className='main-content sensor'>
      {isLoading && <OverlayLoader />}
      <div className='content-header'>
        <h1 className='page-title'>{thing?.name} <sub>{thing?.city.name}</sub></h1>
        <div className='select'>
            <span className='range'>Default Range: </span>
            <Select
                style={{
                    width: "200px",
                    background: "transparent"
                }}
                loading={false}
                value={defaultTimeRange}
                options={dateOptions}
                onChange={(value, option) => {
                  setDefaultTimeRange(value)
                }}
            />
        </div>
        <Dropdown trigger={["click", "contextMenu"]} dropdownRender={renderExportOptions}>
            <button className='button button-primary'>
              <BiExport style={{verticalAlign: "middle"}} />&nbsp;&nbsp;Export Data
            </button>
        </Dropdown>
      </div>
      <div className='graphs-container'>
            <Card
            cover
            loading={isFetching}
            className='weather-summary child weather-summary-container'>
              {weather.length > 0 &&
                <MeasuresSummary weather={weather} aqi={aqiData} />
              }
            </Card>
            <Card
            loading={isFetching}
            className='sensor-summary child'>
              { thing && <>
                <p className='location'>{thing?.city.name} &gt; {thing?.name}</p>
                <p className='timezone-offset'>GMT +5:45 <span className='image-container'><img src='/images/nepal.png' alt='nepal flag'/></span></p>
                <div className='sensor-summary-inner'>
                  <p className='sensor-info'>Sensor State: <span className='value'>{getStringFromSlug(thingStatus)}</span></p>
                  <p className='sensor-info'>Sensor ID: <span className='value'>{thing?.id}</span></p>
                  <div className='lat-lon-container'>
                    <p className='lat-lon'>Latitude:</p>
                    <TbWorldLatitude className='icon' />
                    <p className='lat-lon-data'>{thing?.lat} &deg;N</p>
                  </div>
                  <div className='lat-lon-container'>
                    <p className='lat-lon'>Longitude:</p>
                    <TbWorldLongitude className='icon' />
                    <p className='lat-lon-data'>{thing?.lon} &deg;E</p>
                  </div>
                </div>
                </>
              }
            </Card>     
              <AQIBarChart measure_data={measure_data_aqi()} title={"AQI"} unit={""} />
              <TimeSeriesChart measure_data={graphData?.temperature} title={"Temperature"} unit={"°C"} />
              <TimeSeriesChart measure_data={graphData?.rh} title={"RH"} unit={"%H"} />
              <TimeSeriesChart measure_data={graphData?.pm10} title={"PM 1.0"} unit={"μg/m³"} />
              <TimeSeriesChart measure_data={graphData?.pm25} title={"PM 2.5"} unit={"μg/m³"} />
              <TimeSeriesChart measure_data={graphData?.pm40} title={"PM 4.0"} unit={"μg/m³"} />
              <TimeSeriesChart measure_data={graphData?.pm010} title={"PM 10"} unit={"μg/m³"} />
              <TimeSeriesChart measure_data={graphData?.voc} title={"VOC"} unit={"index"} />
        </div>
    </main>
    </>
  )
}

export default Sensor