import React from 'react'
import { getMessageStatus, getShortDateTimeForMessage } from '../../utility'

const Message = ({message}) => {
  return (
    <div className='message'>
      <div className='text'>
        <p className='subject'>
          {message.subject}
        </p>
        <p className='text-message'>
            {message.message}
        </p>
      </div>
      <div className='image-container'>
          <img src='/images/user-man.png' alt='' />
      </div>
      <p className='extra'>
          {getShortDateTimeForMessage(message.created)} | {getMessageStatus(message.status)}
      </p>
    </div>
  )
}

export default Message