import React from 'react'
import { BsFacebook, BsTwitter, BsInstagram } from "react-icons/bs";
import "../styles/footer.scss";
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <>
      <footer>
        <div className='top'>
          <div className='logo-container'>
            <div className='logo'>
              <img src='https://www.recentair.com/images/logo.png' alt='' />
            </div>
          </div>
          <div className='internal-links'>
            <p className='title'>
              Your Cities
            </p>
            <div className='links'>
              <Link to="" className='link'>Birtamode</Link>
              <Link to="" className='link'>Baneshwor</Link>
              <Link to="" className='link'>Dharan</Link>
              <Link to="" className='link'>Lalipur</Link>
            </div>
          </div>
          <div className='get-in-touch'>
            <p className='title'>Get in touch</p>
            <p className='description'>Questions or feedback? We would love to hear from you.</p>
            <div className='links-container'>
              <a href='#' className='link'><BsFacebook /></a>
              <a href='#' className='link'><BsInstagram /></a>
              <a href='#' className='link'><BsTwitter /></a>
            </div>
          </div>
        </div>
        <p className='copy-text'>Copyright &copy; {new Date().getFullYear()}, <span className="bold">RecentAir | Heisenberg Engineering Private Limited</span>. All Rights Reserved.</p>
    </footer>
    </>

  )
}

export default Footer