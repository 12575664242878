import React, { useState } from 'react'
import "../styles/message.scss";
import Message from '../components/message/Message';
import { message } from 'antd';
import { getMessages, sendMessage } from '../apis/messages';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import OverlayLoader from "../components/loaders/OverlayLoader";
import { getError } from '../utility';
import { FiSend } from "react-icons/fi";
import { MdClose } from "react-icons/md";

const MessagePage = () => {
    const [introShown, setIntroShown] = useState(true);
    const [messages, setMessages] = useState([]);
    const [currentMessage, setCurrentMessage] = useState({
      message: "",
      subject: "",
    });
    const queryClient = useQueryClient();
    const { isLoading } = useQuery(
      "get-client-requests",
      () => getMessages(),
      {
        onSuccess: (data) => {
          // console.log(data.data.results);
          setMessages(data.data.results);
        },
        onError: (error) => message.error(getError(error)),
        refetchInterval: 5 * 1000 * 60,
      }
    )
    const { mutate: sendMessageMutate, isLoading: isSending } = useMutation(() => sendMessage({ message: currentMessage }), {
      onSuccess: (data) => {
          message.success("Request/Query submitted successfully!");
          setCurrentMessage({
            message: "",
            subject: "",
          });
          queryClient.invalidateQueries("get-client-requests");
      },
      onError: (error) => message.error(getError(error))
    })
  
    const checkAndSendMessage = (e) => {
      e.preventDefault();
      if (currentMessage.message && currentMessage.subject) {
        return sendMessageMutate();
      }
      return message.error("Both Subject and Request/Query are mandatory!");
    }
  return (
    <>
    {isSending &&
    <OverlayLoader title="Sending..." key={"message-loader"} />
    }
    <div className='message-page'>
        <div className='page-header'>
            <div className='right-container'>
                <div className='image-container'>
                    <img src='/images/admin.png' alt='' />
                </div>
                <p className='title'>Recent Air Admin</p>
                <p className='dot'>Active</p>
            </div>
        </div>
        <div className='page-body'>
            <div className='message-box'>
                <div className='messages'>
                    {messages.length > 0 ? messages.map(message =>
                    <Message message={message} key={message.id} />
                    ) : <p className="no-messages">No messages yet!</p>}
                </div>
                <div className='form'>
                    {introShown && 
                        <div className='intro'>
                        <p className='text'>
                            Your previous message and it's current state will be in the history above. Feel free to ask any queries to us, we will set the state of your query as soon as possible.
                        </p>
                        <button type='button' className='exit' onClick={ () => setIntroShown(false)
                        }><MdClose /></button>
                        </div>
                    }
                    <form onSubmit={checkAndSendMessage}>
                    <div className='inputs'>
                        <input type='text' placeholder='Subject*' value={currentMessage.subject} onChange={(e) => setCurrentMessage({...currentMessage, subject: e.target.value})} />
                        <textarea placeholder='Request/Query*' onChange={(e) => setCurrentMessage({...currentMessage, message: e.target.value})} value={currentMessage.message} />
                    </div>
                    <button type='submit' className='submit'><FiSend /></button>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default MessagePage