import React from 'react'

const Detail = ({title, states, isClientProp = false}) => {
    const {currentState, setCurrentState, key, isEditing} = states;
  return (
    <div className='detail'>
        <p className='title'>{title}</p>
        <input type="text" disabled={!isEditing} value={currentState ? isClientProp ? currentState[key] : currentState.user[key] : ""} onChange={(e) => {
          if (isClientProp) {
            return setCurrentState({...currentState, [key]: e.target.value})
          } else {
            let new_user = currentState.user;
            new_user = {...new_user, [key]: e.target.value}
            return setCurrentState({...currentState, user: new_user})
          }
        }} />
    </div>
  )
}

export default Detail