import { apiDomain } from "../constants";
import axios from "../myaxios";

export const getProfile = async () => {
    return await axios.get(`${apiDomain}clients/profile/`);
}

export const updateProfile = async ({ profile }) => {
    return await axios.put(`${apiDomain}clients/profile/`, { profile });
}

export const updateProfilePicture = async () => {
    return await axios.put(`${apiDomain}clients/profile-picture/`);
}