import React from 'react'
import { NavLink } from 'react-router-dom'
import { FiMessageCircle, FiUser } from "react-icons/fi";
import { MdSensors } from "react-icons/md";
import { TbApi } from "react-icons/tb";
import "../styles/nav-side.scss";

const SideNav = ({navUtils}) => {
    const {navOpen, setNavOpen, setNavState} = navUtils;
  return (
    <nav className={`side-nav-container${navOpen ? " nav-open" : ""}`}>
        <div className='side-nav'>
            <div className='links-container'>
                {/* <NavLink to="/" className='link' title='Home'><FiHome /><span className='link-name'>Home</span></NavLink> */}
                <NavLink to="/places" className='link' title='All Sensors' onClick={() => setNavOpen(false)}><MdSensors /><span className='link-name'>All Places</span></NavLink>
                <NavLink to="/profile" className='link' title='Profile' onClick={() => setNavOpen(false)}><FiUser /><span className='link-name'>Profile</span></NavLink>
                <NavLink to="/apis" className='link' title='Your APIs' onClick={() => setNavOpen(false)}><TbApi /><span className='link-name'>Your APIs</span></NavLink>
                <NavLink to="/message" className='link' title='Queries and Requests' onClick={() => setNavOpen(false)}><FiMessageCircle /><span className='link-name'>Queries & Requests</span></NavLink>
            </div>
        </div>
    </nav>
  )
}
export default SideNav