import React from 'react'

const Notification = () => {
  return (
    <div className='notification'>
        <p className="text">Your message was marked as <strong>heard</strong> by an admin.</p>
        <small className='sub-text'>I wanted to know about all the...</small>
    </div>
  )
}

export default Notification