import axios from "../myaxios";
import normalAxios from "axios";
import {apiDomain} from "../constants";


export const getInitialPlaces = async () => {
    return await axios.get(`${apiDomain}clients/places/`);
};

export const getInitialPlacesDetail = async () => {
    return await axios.get(`${apiDomain}clients/places-detail/`);
}

export const getParticularPlace = async ({place, api_key}) => {
    return await normalAxios.get(`${apiDomain}v1/latest/${place}/?api_key=${api_key}`);
};

export const getParticularPlaceData = async ({place, api_key, from_str, to_str, data="all"}) => {
    return await axios.get(`${apiDomain}v1/${place}/?api_key=${api_key}&from=${from_str}&to=${to_str}`)
};

export const downloadData = async ({place, from_str, to_str, as, datafor = "all"}) => {
    return await axios.get(`${apiDomain}clients/places/${place}/download/?from=${from_str}&to=${to_str}&as=${as}&datafor=${datafor}&city=${place}`);
};
