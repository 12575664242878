// import "reset.css";
import { BrowserRouter as Router, Routes, Route, Outlet } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import AppLayout from "./components/AppLayout";
import AllSensors from "./pages/AllSensors";
import Apis from "./pages/Apis";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Profile from "./pages/Profile";
import Error404 from "./pages/Error404";

import "./styles/common.scss";
import Sensor from "./pages/Sensor";
import AuthProvider from "./AuthProvider";
import OnlyAnonymous from "./OnlyAnonymous";
import RequireAuth from "./RequireAuth";
import Message from "./pages/Message";


const queryClient = new QueryClient();
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Router>
          <Routes>
            <Route element={
                <OnlyAnonymous>
                  <Outlet />
                </OnlyAnonymous>
              }
              path="/login"
              >
                <Route element={<Login />} index />
            </Route>
            <Route element={
              <RequireAuth>
                  <AppLayout />
              </RequireAuth>
              }
              path=""
            >
              <Route element={<Home />} index />
              <Route element={<AllSensors />} path="places" />
              <Route element={<Sensor />} path="places/:slug" />
              <Route element={<Profile />} path="profile" />
              <Route element={<Apis />} path="apis" />
              <Route element={<Message />} path="message" />
            </Route>
            <Route path="*" element={<Error404 />} />
          </Routes>
        </Router>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
